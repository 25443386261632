import Typography from 'typography'

const typography = new Typography({
    baseFontSize: '14px',
    baseLineHeight: 1.666,
    headerFontFamily: ['Neotech','Assistant','Avenir Next', 'serif'],
    bodyFontFamily: ['Neotech','Assistant','Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif'],
    // See below for the full list of options.
})

export const { scale, rhythm, options } = typography
export default typography