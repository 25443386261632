import { rhythm, scale } from '@utils/typography'

const defaultTheme = {
    theme: {
        maxWidth: '1180px',
        paddingBox: rhythm(1),
        backgroundLight: '#F4EAD5',
        colorText: '#0f5584',
        colorTextTitle: '#ffffff',
        colorLink: '#ffffff',
        colorLinkHover: '#E94E77',
        colorBorder: '#e3e3e3',
        backgroundLogo: '#fff',
        background: '#3E3E3E',
        fontSizeTiny: scale(-1 / 2).fontSize,
        defaultImageHeight: '550px',
        breakpoints: {
            mobile: '',
            tablet: '824px',
            desktop: '1180px'
        },
        colors: {
            secondary: '#E7511E',
        },
        ecommerce: {
            listing: {
                subtitle: {
                    fontSize: scale(-2/5).fontSize
                },
                title: {
                    fontSize: scale(1 / 5).fontSize
                }
            }
        },
        article: {
            color: '#333',
            title: {
                color: '#fff',
                fontSize: scale(8 / 5).fontSize,
                innerFontSize: scale(1).fontSize
            },
            listing: {
                fontSize: scale(2 / 5).fontSize
            },
            navbar: {
                fontSize: scale(-1 / 4).fontSize,
            }
        },
        header: {
            background: 'rgba(255, 255, 255, 0.5)',
            justify: 'flex-start',
            colorText: '#000000'
        },
        menu: {
            background: 'rgba(255, 255, 255, 0.5)',
            colorLink: '#000000',
            colorLinkHover: '#E94E77'
        },
        triptyque: {
            background: 'transparent'
        },
        citation: {
            fontSize: scale(5 / 5).fontSize,
            fontSizeAuthor: scale(3 / 5).fontSize,
            fontSizeGoogle: scale(-1 / 2).fontSize,
            background: 'transparent'
        },
        footer: {
            background: '#3E3E3E',
            color: '#F4EAD5',
            colorLink: '#F4EAD5',
            accroche: {
                fontSize: scale(3 / 5).fontSize
            },
            mention: {
                fontSize: scale(1 / 50).fontSize
            }
        },
        fontSizes: {
            mobile: {
                default: scale(3 / 5).fontSize
            },
            tablet: {
                default: scale(3 / 5).fontSize
            },
            desktop: {
                default: scale(3 / 5).fontSize
            }
        }
    }
}

export default defaultTheme