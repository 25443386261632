import defaultTheme from "@lib/simba/theme"
import merge from "lodash.merge"

const overrides = {
  backgroundLight: "#ffffff",
  colorText: "#0f5584",
  colorTextTitle: "#ffffff",
  colorLink: "#EC730E",
  colorLinkHover: "rgba(#6D6700, 0.7)",
  grey: "#f8f8f8",
  colors: {
    secondary: "#E7511E",
  },
  header: {
    colorText: "#ffffff",
    background: "#797419",
  },
  menu: {
    background: "#0E3E8A",
    colorLink: "#6D6700",
    colorLinkHover: "rgba(86, 120, 138, 0.7)",
    colorHamburger: "#FFF",
  },
  triptyque: {
    background: "#FCFBFA",
  },
  citation: {
    background: "#EFEFEF",
  },
  footer: {
    background: "#fff",
  },
}

export default merge(defaultTheme.theme, overrides)
