// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-library-simba-templates-article-list-js": () => import("./../../../src/components/library/simba/templates/ArticleList.js" /* webpackChunkName: "component---src-components-library-simba-templates-article-list-js" */),
  "component---src-components-library-simba-templates-contact-js": () => import("./../../../src/components/library/simba/templates/Contact.js" /* webpackChunkName: "component---src-components-library-simba-templates-contact-js" */),
  "component---src-components-library-simba-templates-listing-js": () => import("./../../../src/components/library/simba/templates/Listing.js" /* webpackChunkName: "component---src-components-library-simba-templates-listing-js" */),
  "component---src-components-library-simba-templates-new-article-js": () => import("./../../../src/components/library/simba/templates/NewArticle.js" /* webpackChunkName: "component---src-components-library-simba-templates-new-article-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

